
    import { Component, Vue, Watch } from 'vue-property-decorator'
    import TeacherList from './teacherList/index.vue'
    import TeacherItem from './teacherItem/index.vue'

    @Component({
        name: 'TeacherIntroduced',
        components: { TeacherList, TeacherItem }
    })
    export default class TeacherIntroduced extends Vue {
        private componentName?: string = 'TeacherList'

        private teacherList (): void {
            this.componentName = 'TeacherItem'
            sessionStorage.setItem('aboutUs_teacherIntroduced_componentName', this.componentName)
        }
        private teacherItem (): void {
            this.componentName = 'TeacherList'
            sessionStorage.setItem('aboutUs_teacherIntroduced_componentName', this.componentName)
        }
        beforeMount() {
            let _compName: any = sessionStorage.getItem('aboutUs_teacherIntroduced_componentName')
            if(_compName) {
                this.componentName = _compName
            } else {
                this.componentName = 'TeacherList'
            }
        }
    }
