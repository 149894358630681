
    import { Component, Vue, Prop, Watch, Emit } from 'vue-property-decorator'
    @Component({
        name: 'MineSelect'
    })
    export default class MineSelect extends Vue {
        @Prop() selectStateList!: Array<any>
        @Prop() placeholder!: string
        private selectText: string = ''
        private selectList: Array<any> = []
        private activeIndex: number = -1

        private selectListFlag: boolean = false

        @Watch('selectStateList', { immediate: true, deep: true })
        selectStateListWatch(newVal: Array<any>): void {
            if(newVal && Array.isArray(newVal) && newVal.length > 0) {
                this.selectList = [ ...newVal ]
            }
        }

        @Emit()
        private change (item: any): any {
            return item
        }
        private selectItemEvent (item: any, index: number): void {
            this.activeIndex = index
            this.selectText = item.text
            this.change(item)
            this.selectListFlag = false
        }
    }
