
    import { Component, Vue, Watch } from 'vue-property-decorator'
    import { mapGetters, mapMutations } from 'vuex'

    import EnterpriseIntroduction from './enterpriseIntroduction/index.vue'
    import TeacherIntroduced from './teacherIntroduced/index.vue'
    import CurriculumSystem from './curriculumSystem/index.vue'
    import HelpCenter from './helpCenter/index.vue'
    @Component({
        name: 'AboutUs',
        computed: mapGetters('aboutUs', [ 'anoutUsNav' ]),
        components: { EnterpriseIntroduction, TeacherIntroduced, CurriculumSystem, HelpCenter },
        methods: {
            ...mapMutations('teacher', ['setTeacherId'])
        }
    })
    export default class AboutUs extends Vue {
        anoutUsNav!: Array<string>
        setTeacherId!: any

        private activeIndex: number = 0
        private componentName: string = 'EnterpriseIntroduction'

        @Watch('$route', { immediate: true, deep: true })
        routeWatch(newVal: any): void {
            if(newVal.query.id) {
                this.setTeacherId(newVal.query.id)
                this.activeIndex = 1
                this.componentName = 'TeacherIntroduced'
                sessionStorage.setItem('aboutUs_teacherIntroduced_componentName', 'TeacherItem')
            }
        }

        private changeAboutUsCompName(compName: string, index: number): void {
            this.activeIndex = index
            this.componentName = compName
            sessionStorage.removeItem('aboutUs_teacherIntroduced_componentName')
            sessionStorage.removeItem('aboutUs_teacherIntroduced_teacherId')
        }
    }
